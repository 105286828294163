<template>

    <div  class="w-full h-full flex justify-center safe-area">

        <div class="w-full margin-auto h-full overflow-hidden flex flex-col relative">

            <div class="absolute w-full h-full bg-body z-50 portrait-only flex items-center justify-center">
                <div class="text-5xl text-center">
                    <div class="text-8xl text-aux mb-4">
                        <i class="mdi mdi-phone-rotate-portrait"></i>
                    </div>
                    Please, rotate device to portrait oriention
                </div>
            </div>

            <div v-if="path != '/mobile/home' && path != '/mobile/home/'" class="h-10 bg-filters bg-opacity-50 flex flex-row justify-start items-center px-3">

                <div v-if="noHome" class="h-6 w-6 bg-filters rounded-full flex flex-row justify-center items-center mr-2"
                     @click="$router.go(-1)">
                    <i class="mdi mdi-chevron-left text-aux text-lg"></i>
                </div>

                <div class="h-6 w-6 bg-filters rounded-full flex flex-row justify-center items-center"
                     @click="goHome()">
                    <i class="mdi mdi-home text-aux text-sm"></i>
                </div>

                <div class="text-right ml-auto mr-2">
                    <div class="text-xxs">{{ userData.user_name }}</div>
                    <div class="bg-violet rounded-sm text-3xs px-2 py-1px">
                        {{ globalParamsPretty.role_type_name }}
                        <template v-if="globalParamsPretty.role_type_link !== 'presidencia'">
                            / {{ globalParamsPretty.gui_role_name }}
                        </template>
                    </div>
                </div>

                <img v-show="profilePhoto" class="h-6 w-6 rounded-full bg-cover bg-center shadow-xl" :src="profilePhoto" @error="imageError"/>

            </div>

            <div class="flex flex-1 min-h-0 w-full overflow-auto no-scrollbar">
                <transition
                        name="fade"
                        enter-active-class="animated fadeIn"
                        leave-active-class="animated fadeOut"
                        mode="out-in">
                        
                    <router-view v-if="!isLoadingRegister"/>
                </transition>
            </div>

            <Filters :mobile="true"/>

        </div>

        <transition name="fade" enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut" mode="out-in">
            <div class="fixed h-full w-full p-2 pb-16 z-40" v-if="showComments">
                <div class="bg-filters rounded-lg shadow h-full p-2 relative">
                    <div class="absolute right-0 top-0  pr-2 pt-2">
                        <i class="mdi mdi-close-circle-outline text-xl text-aux-40 hover:text-aux"
                            @click="toggleComments(false)"></i>
                    </div>
                    <comments-container :mobile="true" class="bg-filter rounded h-full"
                        @closeComents="closeComents"/>
                </div>
            </div>
        </transition>

    </div>

</template>

<script>

    const Filters = () => import('@/components/Globals/Filters')
    const CommentsContainer = () => import('@/components/Globals/CommentsContainer')
    import { state, actions } from '@/store'

    export default {
        name: 'AppLayout',
        components: {
            Filters,
            CommentsContainer
        },
        data() {
            return {
                openfilters: false,
                showComments: false,
                generalfilters: false,
                smu: null,
                selectOptions: ['a', 'b', 'c', 'd', 'e'],
                defaultImg: process.env.VUE_APP_AUTH_IMG_USER + 'PersonPlaceholder.96x96x32.png',
                seeComponent: true
            }
        },
        methods: {
            goTo(route) {
                this.openfilters = false
                this.showComments = false
                this.$router.push( { name: route } )
            },
            toggleFilters() {
                this.openfilters = !this.openfilters
                this.generalfilters = !this.generalfilters
            },
            closeFilters() {
                this.openfilters = false
                this.generalfilters = false
            },
            toggleComments(val) {
                if (val === undefined) { this.showComments = !this.showComments }
                else { this.showComments = val }
            },
            closeComents(){ 
                this.showComments = false 
            },
            imageError(event) {
                event.target.src = this.defaultPhoto;
            },
            goHome(){
                if(state.user.screen != 3){
                    this.$router.push({ name: 'homeMobile'})
                } else {
                    this.$router.push({ name: 'HomePersonalDashboard'})
                }
            }
        },
        computed: {
            menu() {
                if (this.$route.path !== '/mobile/home' && this.$route.path !== '/mobile/Accept') { return true }
                else { return false }
            },
            path(){
                return this.$route.path 
            },
            matchedPath(){ 
                return this.$route.matched[1].path 
            },
            globalParams(){ 
                return state.globalParams 
            },
            globalParamsPretty(){

                if(Object.keys(state.globalParamsPretty).length > 0){
                
                    return state.globalParamsPretty.rol

                } else {
                 
                    return state.user.role_default

                }
            },
            profilePhoto() {
                return process.env.VUE_APP_AUTH_IMG_USER + state.user.profile_photo;
            },
            defaultPhoto() {
                return process.env.VUE_APP_AUTH_IMG_USER + 'PersonPlaceholder.96x96x32.png'
            },
            userData(){ 
                return state.user     
            },
            noHome(){

                switch (this.path) {

                    case '/mobile/pipeline/PipelineHomeMobile':
                        return false;
                        break;
                    case '/mobile/pipeline/PipelineHomeMobile/':
                        return false;
                        break;

                    case '/mobile/ProjectsManagement/ProjectsManagementHomeMobile':
                        return false;
                        break;
                    case '/mobile/ProjectsManagement/ProjectsManagementHomeMobile/':
                        return false;
                        break;

                    case '/mobile/Chargeability/SLSMUChargeability':
                        return false;
                        break;
                    case '/mobile/Chargeability/SLSMUChargeability/':
                        return false;
                        break;

                    case '/mobile/InterIntra/SwipSLSMU':
                        return false;
                        break;
                    case '/mobile/InterIntra/SwipSLSMU/':
                        return false;
                        break;

                    case '/mobile/PersonalDashboard/HomePersonalDashboard':
                        return false;
                        break;
                    case '/mobile/PersonalDashboard/HomePersonalDashboard/':
                        return false;
                        break;

                    default:
                        return true;

                }

            },
            isLoadingRegister(){
                return state.isLoadingRegister;
            },
            user(){
                return state.user
            },
            seeDataAs(){
                return state.seeDataAs
            },
            token(){
                return state.token
            }
        },
        watch:{
            globalParams(){ 
                this.closeFilters() 
            },
            $route (to, from){
                if(to.name == 'homeMobile' && state.reloadHome){
                    this.$router.go();
                }
            },
            user(n,o){
                // this.$router.push({name:'homeMobile'})
            }

        },
        mounted(){
            actions.setReloadHome(false)
        }
    }
</script>

<style>

    .move {
        animation: todown infinite .7s ease-in-out alternate;
    }
    @keyframes todown {
        to {
            transform: translateY(-10px);
            color: rgb(172, 111, 199, .3)
        }
    }

    .safe-area{
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }

</style>